@import '../colors.scss';

.mat-checkbox {
  min-height: 48px !important;
  border-radius: 4px;
  background-color: $label-background;
  padding-left: 14px;
  padding-top: 4px;

  label {
    margin-bottom: 0px;

    & .mat-checkbox-label {
      & p {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
  }
}

.custom-control-container {
  display: block;
  position: relative;
  height: 100%;
  margin-bottom: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
