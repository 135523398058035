@import 'global-styles/colors';

.mat-menu-item.menu-item-wrapper {
  height: 32px;
  border-radius: 1px;
  line-height: 2.31;
}

.mat-toolbar-row {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
}
