// xs
@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
}

// sm
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

// md
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .row > .col,
  .row > [class*='col-'] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

// lg
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .row > .col,
  .row > [class*='col-'] {
    padding-right: 12px;
    padding-left: 12px;
  }
}

// xl
@media (min-width: map-get($grid-breakpoints, xl)) {
  .row > .col,
  .row > [class*='col-'] {
    padding-right: 16px;
    padding-left: 16px;
  }
}
