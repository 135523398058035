@use '@angular/material' as mat;

@import '../icons.scss';
@import '../colors.scss';

.mat-checkbox-layout {
  white-space: normal !important;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-checkbox-inner-container {
  margin-left: 0px !important;
}

.mat-radio-label {
  white-space: normal !important;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  .mat-radio-label-content {
    color: $black-default !important;
  }
}

.mat-checkbox-disabled .mat-checkbox-label {
  color: $black-default !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: #dadada !important;
}

.mat-button .mat-button-focus-overlay {
  background-color: transparent !important;
}

$default-typography: mat.define-typography-config(
  $font-family: 'SourceSansPro, "Helvetica Neue", Helvetica, sans-serif',
);

@include mat.core();

$default-theme-primary: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #26c6da,
  500: $primary-default,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  A100: #84ffff,
  A200: #18ffff,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$default-theme-accent: (
  50: #f1f8e9,
  100: #dcedc8,
  200: #c5e1a5,
  300: #aed581,
  400: #9ccc65,
  500: $checkbox-background-checked,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  A100: #ccff90,
  A200: #b2ff59,
  A400: #76ff03,
  A700: #64dd17,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$default-theme-warn: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: $scarlet-default,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary-theme: mat.define-palette($default-theme-primary);
$accent-theme: mat.define-palette($default-theme-accent);
$warn-theme: mat.define-palette($default-theme-warn);

$default-theme: mat.define-light-theme(
  (
    color: (
      primary: $primary-theme,
      accent: $accent-theme,
      warn: $warn-theme,
    ),
    typography: $default-typography,
  )
);

@include mat.core-theme($default-theme);
@include mat.all-component-themes($default-theme);
