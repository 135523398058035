@import 'global-styles/utility-classes/display.scss';
@import 'global-styles/utility-classes/flex.scss';
@import 'global-styles/utility-classes/spacing.scss';
@import 'global-styles/utility-classes/input.scss';
@import 'global-styles/utility-classes/checkbox-overwrite.scss';
@import 'global-styles/utility-classes/radio-button-overwrite.scss';

@import 'global-styles/material-cdk-overwrite/modals.scss';
@import 'global-styles/material-cdk-overwrite/menu.scss';
@import 'global-styles/material-cdk-overwrite/snackbar.scss';

@import 'global-styles/themes/default-theme.scss';

@import 'global-styles/input-elements/input-fields.scss';
@import 'global-styles/buttons/buttons.scss';

@import 'global-styles/fonts.scss';
@import 'global-styles/colors.scss';
@import 'global-styles/typography';

@import 'global-styles/bootstrap-grid/bootstrap-grid.scss';

html {
  font-size: 10px;
}

body {
  background-color: $body-background-default;
  margin: 0;
}

html,
body {
  height: 100%;
}

.nav-li-l1 {
  font-size: 18px;
}

.nav-li-l1.active {
  font-weight: 500 !important;
}

.full-width {
  width: 100%;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.highlighted {
  background-color: $accent-default;
  font-weight: 400;
}

.input-bloodpressure-separator {
  margin-bottom: 1.34375em;
  height: 58px;
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 18px;
  width: 20px;
}

.has-error {
  color: $scarlet-default;
}

// old stuff
.glyphicon-none::before {
  content: '\2122';
  color: transparent !important;
}

::selection {
  background-color: $accent-default;
  color: #000;
}

.wordbreak-by-browser-dictionary {
  /* does not work for chrome */
  hyphens: auto;
}

.demo-header {
  border-top: solid;
  border-top-width: 8px;
  border-color: #ffb3b3;
}

.underline {
  text-decoration: underline;
}

.font-bold {
  font-weight: bold;
}
