// phones and small tablets with smaller side < 768px
@mixin xs-devices {
  @media screen and (orientation: portrait) and (max-width: 576px),
    screen and (orientation: landscape) and (max-width: 576px) {
    @content;
  }
}

@mixin small-devices {
  @media screen and (orientation: portrait) and (min-width: 576px) and (max-width: 767px),
    screen and (orientation: landscape) and (min-width: 576px) and (max-height: 767px) {
    @content;
  }
}

// devices like tablets and bigger (smaller side >= 768px)
@mixin tablet-up {
  @media screen and (orientation: portrait) and (min-width: 768px),
    screen and (orientation: landscape) and (min-height: 768px) {
    @content;
  }
}

// iphone 5 special cases
@mixin very-small-devices {
  @media (max-width: 320px) {
    @content;
  }
}
