body {
  font-family: SourceSansPro;
  font-size: 16px;
}

h1 {
  font-size: 36px;
  font-weight: 300;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 24px;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 16px;
}

h4 {
  font-size: 18px !important;
  font-weight: 500;
}

p {
  font-size: 16px;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 16px;
}

a {
  color: black;
  text-decoration: none;
}

@include media-breakpoint-up(sm) {
  h3 {
    margin-bottom: 24px;
  }

  p {
    max-width: 500px;
  }
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
  body {
    font-size: 16px;
  }

  h1 {
    font-size: 38px;
    line-height: 48px;
  }

  h2 {
    font-size: 26px;
    line-height: 36px;
  }

  h3 {
    font-size: 20px;
    line-height: 32px;
  }

  h4 {
    font-size: 20px;
    line-height: 32px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
    max-width: 560px;
  }

  ul {
    max-width: 560px;
  }
}
