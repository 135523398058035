@import '../colors.scss';

.mat-snack-bar-container.sub-header-snack-bar {
  width: 100vw;
  max-width: initial;
  margin: initial;
  border-radius: 0px;
  top: 100px;
  position: relative;
  text-align: center;
  color: #fff;
}

.mat-snack-bar-container.sub-header-snack-bar .mat-simple-snackbar {
  display: block;
}

.mat-snack-bar-container.error {
  background-color: $snackbar-error-background;
}

.mat-snack-bar-container.warning {
  background-color: $snackbar-warning-background;
}

.mat-snack-bar-container.success {
  background-color: $snackbar-success-background;
}

.mat-snack-bar-container.info {
  background-color: $snackbar-info-background;
}

.mat-snack-bar-container.debug-error-snackbar {
  position: absolute;
  z-index: 100;
  width: 100vw;
  bottom: 0px;
  left: 0px;
  max-width: initial;
  margin: initial;
  border-radius: 0px;
  text-align: center;
  color: #fff;
  background-color: #ff0000;
}
.mat-snack-bar-container.debug-error-snackbar .mat-simple-snackbar {
  display: block;
}

.debug-error-snackbar .mat-simple-snackbar-action {
  position: absolute;
  top: 15px;
  right: 0px;
  color: #fff;
}

.debug-error-snackbar .mat-simple-snackbar-action .mat-button::after {
  @extend .material-icons;
  content: 'close';
  pointer-events: all;
}

.warning-snackbar.mat-snack-bar-container {
  background: $error-bg-hex;
  color: $error-fg;
  padding: 20px 30px;
  max-width: 425px;
}

.confirm-completeness-snack-bar .mat-simple-snackbar-action {
  display: inline-block;
  color: #fff;
}

.confirm-completeness-snack-bar .mat-simple-snackbar-action button {
  text-transform: none;
  text-decoration: underline;
  font-size: inherit !important;
}
