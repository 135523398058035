@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 200;
  src: url('/assets/fonts/SourceSansPro-ExtraLight.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 200;
  src: url('/assets/fonts/SourceSansPro-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/SourceSansPro-Light.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/SourceSansPro-LightItalic.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 400;
  src: url('/assets/fonts/SourceSansPro-RegularItalic.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/SourceSansPro-SemiBold.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 500;
  src: url('/assets/fonts/SourceSansPro-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/SourceSansPro-Bold.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 700;
  src: url('/assets/fonts/SourceSansPro-BoldItalic.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 900;
  src: url('/assets/fonts/SourceSansPro-Black.ttf');
}

@font-face {
  font-family: 'SourceSansPro';
  font-style: italic;
  font-weight: 900;
  src: url('/assets/fonts/SourceSansPro-BlackItalic.ttf');
}
