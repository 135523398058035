@import '../colors.scss';

.mat-radio-button {
  min-height: 48px !important;
  border-radius: 4px;
  background-color: $label-background;
  padding-left: 14px;
  padding-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;

  label {
    margin-bottom: 0px;
  }
}
