@import 'global-styles/bootstrap-grid/bootstrap-grid.scss';
@import 'global-styles/responsive';

.cdk-overlay-backdrop.idv-overlay-backdrop-transparent {
  background: transparent;
}

.cdk-overlay-container {
  // only on non-touch devices (because Android and iOS render nice scrollbars out-of-the-box)
  @media not all and (hover: none) {
    ::-webkit-scrollbar {
      width: 18px;
    }

    ::-webkit-scrollbar-thumb {
      border: 6px solid transparent;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .mat-dialog-actions {
    justify-content: flex-end;
  }

  // super-big-modal (aka. "mega")
  .super-big-modal .mat-dialog-container {
    padding-top: 88px;
    padding-bottom: 88px;
    padding-left: 88px;

    .mat-dialog-content {
      padding-right: 88px;
    }

    @include tablet-up {
      width: 704px;
    }

    @include media-breakpoint-up(lg) {
      width: 776px;
    }
  }

  .big-modal .mat-dialog-container {
    padding-top: 24px;
    padding-bottom: 32px;
    padding-left: 32px;

    .mat-dialog-content {
      padding-right: 32px;
    }

    @include tablet-up {
      width: 564px;
    }
  }

  @include xs-devices() {
    // super-big-modal (aka. "mega") _AND_ big-model
    .super-big-modal .mat-dialog-container,
    .big-modal .mat-dialog-container {
      padding-top: 0;
      padding-bottom: 32px;
      padding-left: 16px;

      .mat-dialog-content {
        padding-right: 24px;

        max-height: none !important;
        padding-top: 88px; // add 56px space for the status-bar to 32px normal padding
      }
    }
  }

  @include small-devices() {
    .padded-modal.big-modal .mat-dialog-container {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .big-modal .mat-checkbox {
    background: none;
    padding-top: 0;
    padding-left: 0;

    .mat-checkbox-layout {
      font-weight: 400;

      .mat-checkbox-inner-container {
        margin-top: 5px;
      }
    }
  }

  .small-modal .mat-dialog-container {
    padding: 24px 32px 32px;

    @include tablet-up {
      width: 384px;
    }
  }

  .faq-big-modal .mat-dialog-container {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 88px;
    height: 90vh;

    .mat-dialog-content {
      padding-top: 88px;
      padding-right: 88px;
      max-height: initial;
    }

    @include tablet-up {
      width: 704px;
    }

    @include media-breakpoint-up(lg) {
      width: 776px;
    }
  }
}
