.mat-button-base,
button {
  &:not(.mat-icon-button, .mat-fab, .mat-mini-fab) {
    padding: 5px 16px;
  }

  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.5px;

  &.idv-form-button {
    min-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;

    &:not(:last-of-type) {
      margin-right: 16px;
    }

    & .mat-button-wrapper {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.mat-mini-fab.mat-primary {
    background-color: transparent;
  }
}

@include media-breakpoint-down(sm) {
  button.idv-form-button {
    flex-grow: 1;
    min-width: initial;
    letter-spacing: 0px;
    font-size: 16px !important;
  }

  button.idv-form-back-button {
    flex-grow: 0;
    min-width: 52px !important;
    width: 52px;
  }
}
