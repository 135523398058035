@import 'global-styles/bootstrap-grid/bootstrap-grid.scss';

.form-field-l {
  width: 500px;
}

.form-field-m {
  width: 400px;
}

.form-field-s {
  width: 180px;
}

.form-field-xs {
  width: 150px;
}

@include media-breakpoint-down(sm) {
  .form-field-l {
    width: 100%;
  }

  .form-field-m {
    width: 100%;
  }

  .form-field-s {
    width: 100%;
  }

  .form-field-xs {
    width: 100%;
  }
}

@include media-breakpoint-between(sm, md) {
  .mat-radio-button {
    max-width: 500px;
  }

  .mat-checkbox {
    max-width: 500px;
  }
}

@include media-breakpoint-up(lg) {
  .mat-radio-button {
    max-width: 456px;
  }

  .mat-checkbox {
    max-width: 456px;
  }
}

@include media-breakpoint-up(xl) {
  .mat-radio-button {
    max-width: 540px;
  }

  .mat-checkbox {
    max-width: 540px;
  }
}
