$primary-default: #007f92;
$scarlet-default: #b00020;
$accent-default: #7bbcc6;

$black-default: rgba(0, 0, 0, 0.87);
$background-default: #f9f9f9;
$body-background-default: rgb(238, 238, 238);

$text-color-normal: $black-default;
$text-color-muted: rgba(0, 0, 0, 0.5);

$text-color-decent: #505d68;

$medium-grey: #7f8c8d;

$error-fg: #ac0b25;
$error-bg: rgba(172, 11, 37, 0.1);
$error-bg-hex: rgb(230, 200, 205);

// ###
// checkbox colors
$checkbox-background-checked: #007b6b;
$label-background: #ecf0f1;

//snackbar-colors
$snackbar-error-background: #e2001a;
$snackbar-warning-background: #ff5500;
$snackbar-success-background: #00c057;
$snackbar-info-background: $primary-default;

// tenant colors
$primary-tenmrdemo01: #b32400;
$primary-swisslife: #d82034;
$primary-zurich: rgba(0, 51, 153, 1);
$primary-continentale: #173251;
$primary-europa: #153878;
$primary-nuernberger: rgba(0, 45, 82, 1);
